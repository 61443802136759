var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-reservation-modify-refund-insurance",
      "title": _vm.$t('reservation[\'Refund of insurance package\']'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "no-close-on-backdrop": "",
      "size": "lg"
    },
    on: {
      "show": _vm.handleShowModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var close = _ref.close;
        return [_c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(186, 191, 199, 0.15)',
            expression: "'rgba(186, 191, 199, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "center rounded-pill width-100",
          attrs: {
            "size": "md",
            "variant": "outline-secondary"
          },
          on: {
            "click": function click($event) {
              return close();
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('reservation.close')) + " ")]), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(113, 102, 240, 0.15)',
            expression: "'rgba(113, 102, 240, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          attrs: {
            "variant": "warning",
            "disabled": !_vm.isNumber(_vm.reason),
            "pill": ""
          },
          on: {
            "click": _vm.handleRefundInsurance
          }
        }, [_c('span', [_vm._v(_vm._s(_vm.$t('reservation[\'refundCover\']')))])])];
      }
    }])
  }, [_vm.dataInsuranceItem ? _c('div', [_c('div', {
    staticClass: "fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'information\']')) + ": ")]), _c('ul', [_c('li', [_vm._v(_vm._s(_vm.$t('reservation[\'Insurance package\']')) + ": "), _c('span', {
    staticClass: "fw-700"
  }, [_vm._v(" " + _vm._s(_vm.dataInsuranceItem.planName) + " ")])]), _c('li', [_vm._v(_vm._s(_vm.$t('reservation[\'customer\']')) + ": "), _c('span', {
    staticClass: "fw-700"
  }, [_vm._v(" " + _vm._s(_vm.dataInsuranceItem.passengerName) + " ")])])])]) : _vm._e(), _c('div', {
    staticClass: "mt-1"
  }, [_c('div', {
    staticClass: "d-flex align-items-center fw-700 mb-25"
  }, [_vm._v(" " + _vm._s(_vm.$t('reservation[\'Reason\']')) + " "), _c('span', {
    staticClass: "text-danger mx-25"
  }, [_vm._v(" * ")]), _vm._v(" : ")]), _c('v-select', {
    attrs: {
      "options": _vm.reasonsList,
      "clearable": false,
      "searchable": false,
      "reduce": function reduce(v) {
        return v.value;
      },
      "label": "label",
      "placeholder": _vm.$t('reservation[\'placeholderSelect\']')
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(item) {
        return [_c('div', {
          staticClass: "fw-700"
        }, [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(item.label))) + " ")])];
      }
    }, {
      key: "selected-option",
      fn: function fn(item) {
        return [_vm._v(" " + _vm._s(_vm.$t("reservation.".concat(item.label))) + " ")];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.reason,
      callback: function callback($$v) {
        _vm.reason = $$v;
      },
      expression: "reason"
    }
  })], 1), _c('div', {
    staticClass: "mt-1 fw-700 mb-25"
  }, [_vm._v(" Note: ")]), _c('b-form-textarea', {
    attrs: {
      "rows": "4",
      "formatter": _vm.trimInput255,
      "placeholder": '...'
    },
    model: {
      value: _vm.notes,
      callback: function callback($$v) {
        _vm.notes = $$v;
      },
      expression: "notes"
    }
  }), _c('div', {
    class: "mt-50 mr-50 text-right text-italic fw-700 ".concat(_vm.notesCount === 0 ? 'text-danger' : 'text-info')
  }, [_vm._v(" " + _vm._s(_vm.notesCount) + " / 255 ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }