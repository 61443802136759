<template>
  <b-modal
    id="modal-reservation-modify-refund-insurance"
    :title="$t('reservation[\'Refund of insurance package\']')"
    title-class="text-airline font-medium-4 font-weight-bolder"
    centered
    no-close-on-backdrop
    size="lg"
    @show="handleShowModal"
  >
    <div v-if="dataInsuranceItem">
      <div class="fw-700">
        {{ $t('reservation[\'information\']') }}:
      </div>

      <ul>
        <li>{{ $t('reservation[\'Insurance package\']') }}: <span class="fw-700"> {{ dataInsuranceItem.planName }} </span> </li>
        <li>{{ $t('reservation[\'customer\']') }}: <span class="fw-700"> {{ dataInsuranceItem.passengerName }} </span></li>
      </ul>
    </div>

    <div class="mt-1">
      <div class="d-flex align-items-center fw-700 mb-25">
        {{ $t('reservation[\'Reason\']') }} <span class="text-danger mx-25"> * </span> :
      </div>
      <v-select
        v-model="reason"
        :options="reasonsList"
        :clearable="false"
        :searchable="false"
        :reduce="(v) => v.value"
        label="label"
        :placeholder="$t('reservation[\'placeholderSelect\']')"
      >
        <template #option="item">
          <div class="fw-700">
            {{ $t(`reservation.${item.label}`) }}
          </div>
        </template>
        <template #selected-option="item">
          {{ $t(`reservation.${item.label}`) }}
        </template>
        <template #no-options>
          {{ $t('noOptions') }}
        </template>
      </v-select>
    </div>

    <div class="mt-1 fw-700 mb-25">
      Note:
    </div>
    <b-form-textarea
      v-model="notes"
      rows="4"
      :formatter="trimInput255"
      :placeholder="'...'"
    />
    <div :class="`mt-50 mr-50 text-right text-italic fw-700 ${notesCount === 0 ? 'text-danger' : 'text-info'}`">
      {{ notesCount }} / 255
    </div>

    <template #modal-footer="{close}">
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        size="md"
        variant="outline-secondary"
        class="center rounded-pill width-100"
        @click="close()"
      >
        {{ $t('reservation.close') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="warning"
        class=""
        :disabled="!isNumber(reason)"
        pill
        @click="handleRefundInsurance"
      >
        <span>{{ $t('reservation[\'refundCover\']') }}</span>
      </b-button>
    </template>
  </b-modal>

</template>

<script>
import {
  BModal, BButton, BFormTextarea,
} from 'bootstrap-vue'
import {
  computed,
  ref, toRefs,
} from '@vue/composition-api'
import vSelect from 'vue-select'
import isNumber from 'lodash/isNumber'

import useReservationHandle from '@reservation/useReservationHandle'

import useToast from '@useToast'

export default {
  components: {
    BModal,
    BButton,
    BFormTextarea,

    vSelect,
  },
  props: {
    bookingData: {
      type: Object,
      default: () => {},
    },
    dataInsuranceItem: {
      type: [Object, null],
      default: () => null,
    },
  },
  setup(props) {
    const { toastError, toastSuccess } = useToast()
    const { cancelInsurance } = useReservationHandle()
    const { bookingData, dataInsuranceItem } = toRefs(props)

    const reasonsList = [
      {
        label: 'Change itinerary',
        value: 1,
      },
      {
        label: 'Cannot apply for Visa',
        value: 0,
      },
      {
        label: 'Other reasons',
        value: 2,
      },

    ]

    const reason = ref(1)
    const notes = ref('')

    const notesCount = computed(() => {
      const count = 255 - notes.value.length
      if (count < 0) return 0
      return count
    })

    const showErr = ref(false)
    let toastTimeout = null

    const trimInput255 = value => {
      if (value.length > 255) {
        if (!showErr.value) {
          toastError({ title: 'reservation[\'Only enter up to 255 characters!\']' })
          showErr.value = true
          toastTimeout = setTimeout(() => {
            showErr.value = false
          }, 2500)
        }
      } else {
        showErr.value = false
        clearTimeout(toastTimeout)
      }
      return value.slice(0, 255)
    }

    function handleShowModal() {
      reason.value = 1
      notes.value = ''
    }

    function handleRefundInsurance() {
      if (!isNumber(reason.value)) {
        toastError({ title: 'reservation[\'Please select a return reason\']' })
        return
      }

      const payload = {
        insuranceId: dataInsuranceItem.value.id,
        bookingCode: bookingData.value.bookingCode,
        reason: reason.value,
        notes: notes.value,
      }

      this.$bvModal.show('modal-api-loading')
      cancelInsurance(payload)
        .then(() => {
          toastSuccess({ title: 'reservation[\'Insurance package completed successfully\']' })
        })
        .catch(() => {
          toastError({ title: 'reservation[\'Insurance package refund failed, please check the information again\']' })
        })
        .finally(() => {
          this.$bvModal.hide('modal-api-loading')
          this.$bvModal.hide('modal-reservation-modify-refund-insurance')
        })
    }

    return {
      notes,
      reason,
      reasonsList,
      notesCount,
      trimInput255,

      isNumber,
      handleShowModal,
      handleRefundInsurance,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
